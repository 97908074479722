import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Locale } from 'features/localization';
import { AboutPage } from 'features/homepage';
import { ExternistNode } from 'types/externist';

interface DataType {
  allExternistsJson: {
    nodes: ExternistNode[];
  };
}

function Page({ data, pageContext: { locale } }: PageProps<DataType, { locale: Locale }>) {
  const {
    allExternistsJson: { nodes: externistNodes },
  } = data;

  return <AboutPage externists={externistNodes} locale={locale} />;
}

export default Page;

export const pageQuery = graphql`
  query AboutPageQuery {
    allExternistsJson {
      nodes {
        id
        title
        workTitle {
          de
          en
        }
      }
    }
  }
`;
